import React, { useMemo } from 'react'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { OUTSIDE_ASIA_COUNTRY_CODES } from './constants'
import { Col, NVInput, T } from '@nv/react-commons/src/Components'
import { CountryUtils, ValidatorUtils } from '@nv/react-commons/src/Utils'
import { StyledForm, StyledFormItem, StyledRow } from 'containers/FPLOrderCreate/styles'
import { useIntl } from 'hooks/common'
import { EditAddressProps } from './types'
import { RedAsterisk } from 'containers/FPLRegistration/styles'
import { toLower } from 'lodash'

const EditAddress = ({ form, country }: EditAddressProps) => {
  const intl = useIntl()
  // TODO: Remove this function and reuse isRequiredPostcode() in utils.ts after merging with FPL-5923
  const isRequiredPostCode = useMemo(() => {
    const lowercaseCountry = toLower(country)
    return lowercaseCountry === COUNTRIES.SG || lowercaseCountry === COUNTRIES.MY || lowercaseCountry === COUNTRIES.TH
  }, [country])

  const renderAddressByCountry = useMemo(
    () => () => {
      const commonRules = [
        {
          required: true,
          message: intl.formatMessage({ id: 'address_required' })
        },
        {
          max: 255,
          message: intl.formatMessage({ id: 'address_restriction' })
        }
      ]
      return (
        <StyledFormItem
          label={
            <>
              <T id='address' /> <RedAsterisk />
            </>
          }
        >
          {form.getFieldDecorator('keyword', {
            initialValue: '',
            rules: commonRules
          })(<NVInput />)}
        </StyledFormItem>
      )
    },
    [form, intl]
  )

  return (
    <StyledForm>
      <StyledRow>
        <Col xs={24} sm={20}>
          {renderAddressByCountry()}
        </Col>
        <Col xs={24} sm={4}>
          <StyledFormItem
            label={
              <>
                <T id='postcode' />
                {isRequiredPostCode && <RedAsterisk />}
              </>
            }
          >
            {form.getFieldDecorator('postcode', {
              initialValue: '',
              rules: [
                {
                  required: isRequiredPostCode,
                  message: intl.formatMessage(
                    { id: 'international_postcode_is_required_for_specific_country' },
                    { country: country.toUpperCase() }
                  )
                },
                {
                  len: country === OUTSIDE_ASIA_COUNTRY_CODES.CN ? 6 : CountryUtils.getPostcodeInfo(country).length,
                  message: intl.formatMessage(
                    { id: 'postcode_restriction' },
                    { x: country === OUTSIDE_ASIA_COUNTRY_CODES.CN ? 6 : CountryUtils.getPostcodeInfo(country).length }
                  )
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={24} sm={24}>
          <StyledFormItem label={<T id='building_floor_unit' />}>
            {form.getFieldDecorator('address2', {
              initialValue: '',
              rules: [
                {
                  max: 255,
                  message: intl.formatMessage({ id: 'address_restriction' })
                }
              ]
            })(<NVInput placeholder='optional' />)}
          </StyledFormItem>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={24} sm={8}>
          <StyledFormItem
            label={
              <>
                <T id='name' />
                <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('name', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: `${intl.formatMessage({ id: 'name' })} ${intl.formatMessage({ id: 'is_required' })}`
                },
                {
                  max: 255,
                  message: `${intl.formatMessage({ id: 'international_name_less_than_255_characters' })}`
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
        <Col xs={24} sm={8}>
          <StyledFormItem
            label={
              <>
                <T id='phone' />
                <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('contact', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'international_phone_number_is_required' })
                },
                {
                  max: 32,
                  message: intl.formatMessage({
                    id: 'phone_number_restriction'
                  })
                },
                {
                  validator: ValidatorUtils.phoneNumber,
                  message: `${intl.formatMessage({ id: 'phone' })} ${intl.formatMessage({
                    id: 'must_be_the_valid_number_format'
                  })}`
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
        <Col xs={24} sm={8}>
          <StyledFormItem label={<T id='email' />}>
            {form.getFieldDecorator('email', {
              initialValue: '',
              rules: [
                {
                  type: 'email',
                  message: intl.formatMessage({ id: 'international_invalid_email_format' })
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
      </StyledRow>
    </StyledForm>
  )
}

export { EditAddress }
