import React, { useContext } from 'react'
import { OrdersStepProps } from './types'
import { CURRENY_COUNTRY_LABELS, GOODS_CURRENCY_DATA } from 'containers/FPLOrderCreate/constants'
import { FileUpload } from './FileUpload'
import { buildBoxesForB2BOrder, buildBoxesForB2COrder, isB2CBoxes } from './utils'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { OCMethodSelection } from './OCMethodSelection'
import { ManualKeyboard } from './ManualKeyboard'
import { OCMethod } from './constants'

const OrdersStep = ({ ccType, saveOCMethod, selectedService }: OrdersStepProps) => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { destination_country: destinationCountry } = ocState.selectedService
  const { validOrders, invalidOrders,
    allOrders, fileName,
    validRows, invalidRows,
    ocMethod,
    activeGroupId,
    orderFormGroup
  } = ocState

  const changeMethod = method => {
    saveOCMethod(method)
  }

  const handleClickOptionButton = type => {
    changeMethod(type)
  }

  const handleSaveBulkUpload = () => {
    const currency = CURRENY_COUNTRY_LABELS[destinationCountry] || GOODS_CURRENCY_DATA.USD
    const boxData = isB2CBoxes(ccType)
      ? buildBoxesForB2COrder(validOrders, currency, destinationCountry)
      : buildBoxesForB2BOrder(validOrders, currency, destinationCountry)
    updateOCState({ boxOrders: boxData })
  }

  const handleProcessDataUpload = (
    {
      allOrders: aOrders,
      validRows: vRows,
      invalidRows: inRows,
      validItemisedOrders: vItemisedOrders,
      invalidItemisedOrders: inItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfInvalidItemsInInvalidOrders,
      numberOfValidItemsInInvalidOrders
    },
    uploadedFileName
  ) => {
    updateOCState({
      allOrders: aOrders,
      validOrders: vItemisedOrders,
      invalidOrders: inItemisedOrders,
      validRows: vRows,
      invalidRows: inRows,
      fileName: uploadedFileName,
      numberOfValidItemsInValidOrders,
      numberOfInvalidItemsInInvalidOrders,
      numberOfValidItemsInInvalidOrders
    })
  }

  return (
    <>
      {!ocMethod && <OCMethodSelection ccType={ccType} onMethodChange={method => changeMethod(method)} />}
      {ocMethod === OCMethod.UPLOAD && (
        <>
          <FileUpload
            destinationCountry={selectedService.destination_country}
            allOrders={allOrders}
            validOrders={validOrders}
            invalidOrders={invalidOrders}
            validRows={validRows}
            invalidRows={invalidRows}
            fileName={fileName}
            ccType={ccType}
            onMethodChange={handleClickOptionButton}
            onSaveOrders={handleSaveBulkUpload}
            onProcessOrders={handleProcessDataUpload}
          />
        </>
      )}
      {ocMethod === OCMethod.KEYBOARD && (
        <>
          <ManualKeyboard
            onMethodChange={method => changeMethod(method)}
            activeGroupId={activeGroupId}
            orderFormGroup={orderFormGroup}
            updateOCState={updateOCState}
          />
        </>
      )}
    </>
  )
}

export { OrdersStep }
