import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { compose } from 'redux'
import { useIntl } from 'hooks/common'
import { Form, message, T, Spin } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import { AddressText } from 'components/AddressText'
import { Preview } from 'components/Preview'
import { SenderInfoProps, Address } from './types';
import { SpinWrapperStyled } from 'containers/FPLOrderCreate/styles'
import { EditAddress } from './EditAdrress'
import { faWarehouseAlt } from '@fa-pro-light/faWarehouseAlt'
import { DataList } from './DataList'
import { SUB_STEP_STATUS } from './constants'
import { DataListWrapper } from './styles'

const initState = {
  addressStatus: SUB_STEP_STATUS.CLOSE,
  internalSelectedAddress: null,
  internalCreating: false,
  internalDeleting: false,
  internalEditing: false
}

const AddressSelectionForm = ({
  onCancel,
  onAddAddress,
  onEdit,
  onChangeSelectedAddress,
  status,
  title,
  form,
  country,
  selectedAddress,
  addresses,
  activeAddress,
  fetchingAddress,
  addingAddress
}: SenderInfoProps) => {
  const [state, setState] = useState(initState)

  const intl = useIntl()

  const { addressStatus, internalSelectedAddress } = state

  useEffect(() => {
    setState(prevState => ({ ...prevState, internalSelectedAddress: activeAddress }))
  }, [activeAddress])

  const handleValidateFields = useCallback(() => {
    form.validateFields((err, values) => {
      if (!err) {
        const addr = {
          address1: values.keyword,
          address2: values.address2,
          alias: values.name,
          country,
          name: values.name,
          postcode: values.postcode,
          contact: values.contact,
          email: values.email
        }
        if (addressStatus === SUB_STEP_STATUS.IN_ADDING) {
          onAddAddress(addr as Address)
        }
        setState(prevState => ({
          ...prevState,
          addressStatus: SUB_STEP_STATUS.IN_LIST,
        }))
      }
    })
  }, [addressStatus, form, onAddAddress])

  const handleAddAnother = () => {
    setState(prevState => ({ ...prevState, addressStatus: SUB_STEP_STATUS.IN_ADDING }))
  }

  const handleEditStep = () => {
    setState(prevState => ({
      ...prevState,
      addressStatus: SUB_STEP_STATUS.IN_LIST
    }))
    onEdit()
  }

  const handleSaveAddress = () => {
    if (addressStatus === SUB_STEP_STATUS.IN_LIST) {
      if (!internalSelectedAddress) {
        message.error(intl.formatMessage({ id: 'select_or_create_address' }))
      } else {
        onChangeSelectedAddress(internalSelectedAddress)
      }
    }
    if (addressStatus === SUB_STEP_STATUS.IN_ADDING) {
      handleValidateFields()
    }
  }

  const handleCancelAddress = () => {
    if (addressStatus === SUB_STEP_STATUS.IN_ADDING) {
      setState(prevState => ({ ...prevState, addressStatus: SUB_STEP_STATUS.IN_LIST }))
    } else {
      onCancel()
      setState(prevState => ({ ...prevState, internalSelectedAddress: selectedAddress }))
    }
  }

  const handleChangeItemList = indicatedAddressId => {
    const address = addresses.find(add => add.id === indicatedAddressId)
    setState(prevState => ({ ...prevState, internalSelectedAddress: address }))
  }

  const renderActive = () => {
    if (addressStatus === SUB_STEP_STATUS.IN_ADDING) {
      return <EditAddress form={form} country={country} />
    }
    return (
      <DataListWrapper>
        {addingAddress && <Spin />}
        <DataList
          renderRow={renderAddressText}
          data={addresses}
          selectedId={internalSelectedAddress?.id}
          onChange={handleChangeItemList}
        />
      </DataListWrapper>
    )
  }

  const renderAddressText = row => {
    return <AddressText {...row} />
  }

  const renderPreview = useMemo(
    () => () => {
      if (fetchingAddress) {
        return (
          <SpinWrapperStyled>
            <Spin />
          </SpinWrapperStyled>
        )
      }
      if (!selectedAddress) {
        return <Preview title={<T id='no_address' />} disabled />
      }
      return <AddressText {...selectedAddress} />
    },
    [selectedAddress, fetchingAddress]
  )

  return (
    <OCSubStep
      status={status}
      title={title}
      subtitle={'create_address'}
      icon={faWarehouseAlt}
      renderActive={renderActive}
      renderPreview={renderPreview}
      onSave={handleSaveAddress}
      onEdit={handleEditStep}
      onCancel={handleCancelAddress}
      onAddAnother={
        addressStatus !== SUB_STEP_STATUS.IN_ADDING
          ? handleAddAnother
          : null
      }
      onChange={handleChangeItemList}
    />
  )
}

const AddressSelection = compose(Form.create())(React.memo(AddressSelectionForm))

export { AddressSelection }
