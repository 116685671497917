import React, { useContext, useMemo, useState } from 'react'
import Button from 'components/Button'
import { T, Row, Col, Form } from '@nv/react-commons/src/Components'
import { faArrowLeft } from '@fa-pro-light/faArrowLeft'
import { faBoxes } from '@fa-pro-light/faBoxes'
import {
  CREATION_METHODS,
  CURRENY_COUNTRY_LABELS,
  GOODS_CURRENCY_DATA,
  INPUT_METHODS,
  ORDER_CREATE_SOURCE
} from 'containers/FPLOrderCreate/constants'
import { ButtonFullWidth, EndedDivBlock, HorizontalSeperateLine, InputZone, StyledMinCard } from './styles'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { RecipientInSummary } from './RecipientInSummary'
import { KEYBOARD_INPUT_STATUS, MMCC_OC_STEP } from './constants'
import { isB2BBoxes, validateRecipientInGroup } from './utils'
import { PreviewStepInfo } from './PreviewStepInfo'
import { MP_ORDER_TYPE, TRACK_ADDED_INTL_ORDER_DETAIL } from 'containers/FPLMixpanel/constants'
import { mpTrackAddedIntlOrderDetail } from 'containers/FPLMixpanel/helpers'
import { RecipientForm } from './RecipientForm'
import { BoxSection } from './BoxSection'
import { compose } from 'redux'
import { max } from 'lodash'
import { Hspace } from 'components/Hspace'
import { Text } from 'components/Text'

export const ManualKeyboardForm = ({ form, onMethodChange }) => {
  const [isShowMissingBox, setIsShowMissingBox] = useState(false)

  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    keyboardInputStatus,
    selectedService,
    selectedCCType,
    orderFormGroup,
    activeGroupId,
    customsCurrency
  } = ocState
  const { destination_country: destinationCountry } = selectedService

  const totalValuesInPreviewOrder = useMemo(() => {
    let totalBox = 0
    let totalGoodsValue = 0
    Object.values(orderFormGroup).map(group => {
      totalBox += group.confirmedBoxes.length
      const goodValuesInGroup = group.confirmedBoxes.reduce((total, order) => total + order.parcel_details.value, 0)
      totalGoodsValue += goodValuesInGroup
    })
    return {
      totalBox,
      totalGoodsValue
    }
  }, [orderFormGroup])

  const isValidActiveGroup = useMemo(() => {
    const isFilledBoxes = !!orderFormGroup[activeGroupId].confirmedBoxes.length
    const isValidRecipient = validateRecipientInGroup(orderFormGroup[activeGroupId].address, destinationCountry)
    return isFilledBoxes && isValidRecipient
  }, [orderFormGroup, activeGroupId, destinationCountry])

  const totalValidRecipient = useMemo(() => {
    return Object.values(orderFormGroup).filter(group => {
      const isFilledBoxes = !!group.confirmedBoxes.length
      const isValidRecipient = validateRecipientInGroup(group.address, destinationCountry)
      return isFilledBoxes && isValidRecipient
    }).length
  }, [orderFormGroup])

  const initRecipientFormData = useMemo(() => {
    return orderFormGroup[activeGroupId].address
  }, [orderFormGroup, activeGroupId])

  const handleChangeCreationMethod = method => {
    onMethodChange(method)
  }

  const saveOrders = () => {
    updateOCState({
      keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.COMMERCIAL_INVOICE,
      visibleAddBoxForm: false
    })

    const validBoxes = Object.values(orderFormGroup).filter(group => group.confirmedBoxes.length)
    const { PROPS } = TRACK_ADDED_INTL_ORDER_DETAIL
    mpTrackAddedIntlOrderDetail({
      [PROPS.METHOD]: CREATION_METHODS[ORDER_CREATE_SOURCE.KEYBOARD],
      [PROPS.NUMBER_OF_VALID_ORDER]: validBoxes.length,
      [PROPS.NUMBER_OF_INVALID_ORDER]: 0,
      [PROPS.TOTAL_ORDERS]: validBoxes.length,
      [PROPS.ORDER_TYPE]: isB2BBoxes(selectedCCType) ? MP_ORDER_TYPE.MMCC_B2B : MP_ORDER_TYPE.MMCC_B2C
    })
  }

  const handleEditStep = () => {
    updateOCState({ keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING, visibleAddBoxForm: false })
  }

  const addNewRecipient = () => {
    form.validateFields(err => {
      if (!err) {
        const newOrderFormGroup = { ...orderFormGroup }

        if (newOrderFormGroup[activeGroupId].draftBoxes.length) {
          const nextActiveGroupId = max(Object.values(newOrderFormGroup).map(g => g.id)) + 1
          updateOCState({
            activeGroupId: nextActiveGroupId,
            visibleAddBoxForm: true,
            orderFormGroup: {
              ...orderFormGroup,
              [nextActiveGroupId]: {
                id: nextActiveGroupId,
                address: null,
                draftBoxes: [],
                confirmedBoxes: []
              }
            }
          })
          setIsShowMissingBox(false)
          form.resetFields()
        } else {
          setIsShowMissingBox(true)
        }
      }
    })
  }

  const handleShowMissingBox = visible => {
    setIsShowMissingBox(visible)
  }

  const cancelRecipient = () => {
    const activeGroupIndex = Object.values(orderFormGroup)
      .map(g => g.id)
      .findIndex(key => activeGroupId === key)
    const previousGroup = Object.values(orderFormGroup)[activeGroupIndex - 1]
    const newOrderFormGroup = { ...orderFormGroup }
    delete newOrderFormGroup[activeGroupIndex]
    if (previousGroup && previousGroup.id >= 0) {
      updateOCState({
        activeGroupId: previousGroup.id,
        orderFormGroup: newOrderFormGroup
      })
    }
  }

  const selectFormGroup = groupId => {
    form.resetFields()
    updateOCState({
      activeGroupId: groupId,
      visibleAddBoxForm: !orderFormGroup[groupId].draftBoxes.length
    })
  }

  const deleteFormGroup = groupId => {
    form.resetFields()
    const deletedGroupIndex = Object.values(orderFormGroup)
      .map(g => g.id)
      .findIndex(key => groupId === key)
    const previousGroup = Object.values(orderFormGroup)[deletedGroupIndex - 1]

    if (previousGroup) {
      const newOrderFormGroup = { ...orderFormGroup }
      delete newOrderFormGroup[groupId]
      updateOCState({
        activeGroupId: previousGroup.id,
        orderFormGroup: newOrderFormGroup
      })
      return
    }
    const nextGroup = Object.values(orderFormGroup)[deletedGroupIndex + 1]
    if (nextGroup) {
      const newOrderFormGroup = { ...orderFormGroup }
      delete newOrderFormGroup[groupId]
      updateOCState({
        activeGroupId: nextGroup.id,
        orderFormGroup: newOrderFormGroup
      })
      return
    }

    const newOrderFormGroup = { ...orderFormGroup }
    newOrderFormGroup[activeGroupId] = {
      id: activeGroupId,
      address: null,
      confirmedBoxes: [],
      draftBoxes: []
    }
    updateOCState({
      orderFormGroup: newOrderFormGroup
    })
  }

  return (
    <>
      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_SHOWING && (
        <>
          <Row gutter={14}>
            <Col span={18}>
              <StyledMinCard hoverable={false}>
                <Button size='small' icon={faArrowLeft} onClick={() => handleChangeCreationMethod(INPUT_METHODS.NONE)}>
                  <T id='choose_input' allCaps />
                </Button>
                <HorizontalSeperateLine className='mt-2 mb-2' />

                <InputZone>
                  <Text id='recipient_information' type='bold' />
                  <RecipientForm form={form} country={destinationCountry} initFormData={initRecipientFormData} />
                  <BoxSection isShowMissingBox={isShowMissingBox} onShowMissingBox={handleShowMissingBox} />
                </InputZone>

                <HorizontalSeperateLine className='mt-2 mb-2' />
                <EndedDivBlock>
                  {!isValidActiveGroup && (
                    <Button onClick={cancelRecipient} type='default' size='medium'>
                      <T id='cancel' allCaps />
                    </Button>
                  )}
                  <Hspace width={8} />
                  <Button onClick={addNewRecipient} type='primary' size='medium'>
                    <T id='add_new_recipient' allCaps />
                  </Button>
                </EndedDivBlock>
              </StyledMinCard>
            </Col>
            <Col span={6}>
              <RecipientInSummary
                country={destinationCountry}
                totalValidRecipient={totalValidRecipient}
                orderFormGroup={orderFormGroup}
                activeGroupId={activeGroupId}
                onSelectFormGroup={selectFormGroup}
                onDeleteFormGroup={deleteFormGroup}
              />
              <ButtonFullWidth type='primary' size='medium' disabled={!totalValidRecipient} onClick={saveOrders}>
                <T id='save_and_continue' allCaps />
              </ButtonFullWidth>
            </Col>
          </Row>
        </>
      )}

      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_PREVIEW && (
        <PreviewStepInfo
          quantity={totalValuesInPreviewOrder.totalBox}
          unit={customsCurrency}
          creationMethod={INPUT_METHODS.KEYBOARD}
          totalValue={totalValuesInPreviewOrder.totalGoodsValue}
          totalRecipient={totalValidRecipient}
          translatedKeys={{
            titleKey: 'total_recipient',
            totalPluralKey: 'boxes',
            totalSingularKey: 'box',
            pluralRecipientKey: 'plural_recipient',
            singularRecipientKey: 'single_recipient',
            totalValueKey: 'total_goods_value',
            creationMethodKey: 'international_creation_method'
          }}
          icon={faBoxes}
          onEditStep={handleEditStep}
        />
      )}
    </>
  )
}

export const ManualKeyboard = compose(
  Form.create({
    onValuesChange: (props, values) => {
      const { orderFormGroup, activeGroupId, updateOCState } = props
      const updatedGroup = { ...orderFormGroup }
      updatedGroup[activeGroupId].address = {
        ...updatedGroup[activeGroupId].address,
        ...values
      }
      updateOCState({
        orderFormGroup: updatedGroup
      })
    }
  })
)(React.memo(ManualKeyboardForm))
