import React, { useMemo, useEffect } from 'react'
import { StyledBlockRight, StyledForm } from 'containers/FPLOrderCreate/styles'
import Button from 'components/Button'
import { T, Row, Col } from '@nv/react-commons/src/Components'
import { Modal } from 'components/Modal'
import { StyledFormItem } from 'containers/FPLOrderRequestList/styles'
import { useIntl } from 'hooks/common'
import { CancelButton, SmallInput } from './styles'
import { RedAsterisk } from 'containers/FPLRegistration/styles'
import { getCreationBoxFormRules } from './utils'
import { compose } from 'redux'
import { Form } from '@nv/react-commons/src/Components'

export const EditBoxFormModal = ({ form, visible, order, isResetEditForm, onCancel, onOk }) => {
  const intl = useIntl()

  const rulesByField = useMemo(() => getCreationBoxFormRules(intl), [intl])

  useEffect(() => {
    if (isResetEditForm) {
      form.resetFields()
    }
  }, [isResetEditForm, form])

  const submitForm = () => {
    form.validateFields((err, values) => {
      if (!err) {
        onOk({ ...values, boxNo: order.boxNo })
      }
    })
  }

  return (
    <Modal visible={visible} title={<T id='edit_b2b_boxes' />} onCancel={onCancel}>
      <StyledForm>
        <Row>
          <Col span={10}>
            <StyledFormItem
              label={
                <>
                  <T id='shipper_box_id' />
                  <RedAsterisk />
                </>
              }
            >
              {form.getFieldDecorator('boxId', {
                initialValue: order.boxId,
                rules: rulesByField.boxId
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
          <Col span={14}>
            <StyledFormItem
              label={
                <>
                  <T id='goods_description' />
                  <RedAsterisk />
                </>
              }
            >
              {form.getFieldDecorator('goodsDescription', {
                initialValue: order.goodsDescription,
                rules: rulesByField.goodsDescription
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <StyledFormItem
              label={
                <>
                  <T id='no_of_parcels' />
                  <RedAsterisk />
                </>
              }
            >
              {form.getFieldDecorator('quantity', {
                initialValue: order.quantity,
                rules: rulesByField.quantity
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem
              label={
                <>
                  <T id='goods_value' />
                  <RedAsterisk />
                </>
              }
              style={{ marginBottom: '0px' }}
            >
              {form.getFieldDecorator('goodsValue', {
                initialValue: order.goodsValue,
                rules: rulesByField.goodsValue
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className='flex'>
              <StyledFormItem label='L'>
                {form.getFieldDecorator('length', {
                  initialValue: order.length,
                  rules: rulesByField.length
                })(<SmallInput />)}
              </StyledFormItem>

              <StyledFormItem label='W'>
                {form.getFieldDecorator('width', {
                  initialValue: order.width,
                  rules: rulesByField.width
                })(<SmallInput />)}
              </StyledFormItem>
              <StyledFormItem label='H'>
                {form.getFieldDecorator('height', {
                  initialValue: order.height,
                  rules: rulesByField.height
                })(<SmallInput />)}
              </StyledFormItem>
            </div>
          </Col>
          <Col span={12}>
            <StyledFormItem
              label={
                <>
                  <T id='weight_kg' />
                </>
              }
            >
              {form.getFieldDecorator('weight', {
                initialValue: order.weight,
                rules: rulesByField.weight
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
        </Row>
      </StyledForm>
      <StyledBlockRight>
        <CancelButton size='medium' onClick={onCancel}>
          <T id='cancel' />
        </CancelButton>
        <Button type='primary' size='medium' onClick={submitForm}>
          <T id='save' />
        </Button>
      </StyledBlockRight>
    </Modal>
  )
}

export const EditBoxModal = compose(Form.create())(React.memo(EditBoxFormModal))
