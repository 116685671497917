/**
 *
 * 4PL Custom Currency
 *
 */

import React from 'react'
import { Icon } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import PropTypes from 'prop-types'
import { faBoxUsd } from '@fortawesome/pro-light-svg-icons'
import { InfoTooltip } from 'containers/FPLOrderRequestList/Fields'
import { Select } from 'components/Select'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { toUpper } from 'lodash'
import { StyledDivWrapper } from './styles'

const renderTooltip = () => {
  return <InfoTooltip tooltipTextId='international_currency_tooltip' />
}

export const CustomsCurrency = ({ currency, country, onChange }) => {
  const renderActive = () => {
    const currencyByCountry = toUpper(CountryUtils.getCurrencyCode(country))
    return (
      <StyledDivWrapper>
        <div style={{ width: 41 }}>
          <Icon icon={faBoxUsd} style={{ color: '#aaaeb3', fontSize: 24 }} />
        </div>
        <div style={{ width: 120 }}>
          <Select value={toUpper(currency)} onChange={onChange} style={{ width: '100%' }}>
            <Select.Option value={currencyByCountry}>{currencyByCountry}</Select.Option>
            <Select.Option value='USD'>USD</Select.Option>
          </Select>
        </div>
        <div>{renderTooltip()}</div>
      </StyledDivWrapper>
    )
  }

  return <OCSubStep title='custom_currency' status={OCSubStep.OPEN} renderActive={renderActive} hideSave />
}

CustomsCurrency.propTypes = {
  onChange: PropTypes.func,
  currency: PropTypes.string,
  country: PropTypes.string
}
