import React, { useMemo } from 'react'
import { useIntl } from 'hooks'
import { faTrashAlt } from '@fa-pro-light/faTrashAlt'
import { Text } from 'components/Text'
import { NVModal } from '@nv/react-commons/src/Components'
import { HoverName, InlineDiv, ListHeader, NoMarginIcon, PointerGroup, ShortText, StyledRollDiv } from './styles'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { Colors } from 'themes'
import { faPencil } from '@fortawesome/pro-regular-svg-icons'
import { validateRecipientInGroup } from './utils'

export const RecipientInSummary = ({
  orderFormGroup,
  activeGroupId,
  totalValidRecipient,
  country,
  onSelectFormGroup,
  onDeleteFormGroup
}) => {
  const intl = useIntl()

  const confirmDeleteGroup = groupId => {
    NVModal.confirm({
      cancelText: intl.formatMessage({ id: 'no' }),
      content: intl.formatMessage({ id: 'delete_this_recipient' }),
      okText: intl.formatMessage({ id: 'yes' }),
      title: intl.formatMessage({ id: 'are_you_sure' }),
      onOk: () => onDeleteFormGroup(groupId)
    })
  }

  const formGroups = useMemo(() => {
    return Object.values(orderFormGroup)
  }, [orderFormGroup])

  return (
    <div style={{ background: 'white', borderRadius: 4 }}>
      <ListHeader>
        <Text id='total_valid_recipient' type='bold' size={16} />
        <Text type='bold' size={16}>
          {totalValidRecipient}
        </Text>
      </ListHeader>

      <StyledRollDiv>
        {formGroups.map((group: any) => {
          const isFilledBoxes = !!group.confirmedBoxes.length
          const isValidRecipientInfo = validateRecipientInGroup(group.address, country)
          const isValidGroup = isValidRecipientInfo && isFilledBoxes
          return (
            <PointerGroup key={group.id} active={group.id === activeGroupId}>
              <InlineDiv>
                {isValidGroup ? (
                  <NoMarginIcon icon={faCheckCircle} color={Colors.cherryRed} />
                ) : (
                  <NoMarginIcon icon={faPencil} />
                )}
                <HoverName onClick={() => onSelectFormGroup(group.id)} type='bold'>
                  <ShortText>
                    {orderFormGroup[group.id].address?.name || intl.formatMessage({ id: 'new_recipient' })}
                  </ShortText>
                </HoverName>
              </InlineDiv>
              {isValidGroup && <NoMarginIcon icon={faTrashAlt} onClick={() => confirmDeleteGroup(group.id)} />}
            </PointerGroup>
          )
        })}
      </StyledRollDiv>
    </div>
  )
}
