import { uniq, upperCase } from 'lodash'
import { COUNTRY_LABEL, TRANSLATION_KEY_BY_TYPE } from 'containers/FPLParcelDetail/constants'
import {
  DELIVERY_TIME_VALUE,
  DEFAULT_DELIVERY_TIME_VALUE,
  FRIENDLY_FIELD_NAME,
  FRIENDLY_ERROR_MESSAGE,
  ORDER_REQUEST_STATUS,
  ORDER_REQUEST_STATUS_LABEL,
  ORDER_REQUEST_HEADER_FIELDS
} from './constants'
import { ORDER_CREATE_SOURCE } from 'containers/FPLOrderCreate/constants'
import { COUNTRIES } from '@nv/react-commons/src/Constants'

export const parseJSONString = str => {
  try {
    const json = JSON.parse(str)
    return json
  } catch (e) {
    return {}
  }
}

export const friendlyItemsDescription = items => {
  const results = []
  items.forEach(reqItem => {
    if (reqItem.description && !results.includes(reqItem.description)) {
      results.push(reqItem.description)
    }
  })
  return results.length > 1 ? results.join(', ') : results[0]
}

export const parseOrderRequestList = list => {
  return list.map(item => {
    const jsonRequest = parseJSONString(item.request_body)
    if (jsonRequest.items && jsonRequest.items.length) {
      jsonRequest.parcel_details.customs_description = friendlyItemsDescription(jsonRequest.items)
    }
    return {
      ...item,
      request_body: jsonRequest,
      response_body: parseJSONString(item.response_body)
    }
  })
}

export const transformOrderRequest = data => {
  if (!data) return {}
  return {
    name: data.request_body?.to?.name,
    contactNumber: data.request_body?.to?.contact_number,
    email: data.request_body?.to?.contact_email,
    addressLine1: data.request_body?.to?.address_line1,
    city: data.request_body?.to?.city,
    stateProvince: data?.request_body?.to?.state_province,
    postCode: data.request_body?.to?.post_code,
    countryCode: data.request_body?.to?.country_code,
    cashOnDelivery: data.request_body?.delivery?.cash_on_delivery,
    requestedTrackingID: data.request_body?.requested_tracking_id,
    taxNumber: data.request_body?.parcel_details?.tax_id,
    customsDescription: data.request_body?.parcel_details?.customs_description,
    goodsValue: data.request_body?.parcel_details?.value,
    sourceOrderID: data.request_body?.source_order_id,
    deliveryInstructions: data.request_body?.delivery?.delivery_instructions,
    weight: data.request_body?.parcel_details?.weight,
    insuredValue: data.request_body?.delivery?.insured_value,
    sourceReferenceId: data.request_body?.source_reference_id
  }
}

export const transformOrderRequestParams = (orderValues, dataSource, originCountry) => {
  const countryCode = dataSource?.request_body?.to.country_code
  const itemsOrder = dataSource?.request_body?.items || dataSource?.items
  const items = itemsOrder && itemsOrder.length > 0 ? { items: itemsOrder } : {}
  const pickup = dataSource?.request_body?.pickup
  const returnAddress = dataSource?.request_body?.return
  const customCurrency = dataSource?.id
    ? dataSource?.request_body?.parcel_details?.customs_currency
    : orderValues?.goodsCurrency
  const isReturnOrder = dataSource?.request_body?.parcel_details?.is_relabel
  const originalParcelCustomsDescription = dataSource?.request_body?.parcel_details?.customs_description
  const originParcelValue = dataSource?.request_body?.parcel_details?.value
    ? +dataSource.request_body.parcel_details.value
    : null
  return {
    id: dataSource?.id,
    service_code: dataSource?.request_body?.service_code,
    ...(dataSource?.request_body?.type && {
      type: dataSource?.request_body?.type
    }),
    ...(dataSource?.request_body?.source_reference_id && {
      source_reference_id: dataSource?.request_body?.source_reference_id
    }),
    ...(orderValues?.requestedTrackingID && { requested_tracking_id: orderValues.requestedTrackingID }),
    ...(orderValues?.sourceOrderID && { source_order_id: orderValues.sourceOrderID }),
    from: dataSource?.request_body?.from,
    ...(pickup && { pickup }),
    ...(returnAddress && { return: returnAddress }),
    source: dataSource?.id ? dataSource?.request_body?.source : ORDER_CREATE_SOURCE.KEYBOARD,
    to: {
      name: orderValues?.name,
      address_line1: orderValues?.addressLine1,
      city: orderValues?.city,
      post_code: orderValues?.postCode,
      country_code: upperCase(countryCode),
      contact_number: orderValues?.contactNumber,
      contact_email: orderValues?.email,
      ...(orderValues?.stateProvince && { state_province: orderValues.stateProvince })
    },
    parcel_details: {
      tax_id: orderValues?.taxNumber,
      customs_description: orderValues?.customsDescription || originalParcelCustomsDescription,
      customs_currency: customCurrency,
      value: orderValues?.goodsValue ? +orderValues.goodsValue : originParcelValue,
      origin_country: COUNTRIES[originCountry]?.toUpperCase(),
      ...(orderValues?.weight && { weight: +orderValues.weight || 0 }),
      ...(isReturnOrder && { is_relabel: isReturnOrder })
    },
    delivery: {
      cash_on_delivery: +orderValues?.cashOnDelivery || 0,
      delivery_instructions: orderValues?.deliveryInstructions,
      insured_value: +orderValues?.insuredValue || 0
    },
    ...items
  }
}

export const getResponseError = responseBody => {
  const err = responseBody?.error?.validation_errors || responseBody?.error

  return err
}

export const getDeliveryIndexByRange = timeSlot => {
  return DELIVERY_TIME_VALUE[timeSlot] !== undefined ? DELIVERY_TIME_VALUE[timeSlot] : DEFAULT_DELIVERY_TIME_VALUE
}

export const errorMessage = (error, intl) => {
  if (!error || (Object.values(error) && Object.values(error).length === 0)) return ''
  const errorKeys = Object.keys(error)
  const errorValues = Object.values(error)

  const str = errorValues.reduce((acc, next, index) => {
    let key = errorKeys[index]
    if (key.includes('items')) {
      key = `items.${key.split('.')[1]}`
    }
    next = next + ''
    const errorContent = next.substr(next.indexOf(' ') + 1)
    const fieldName = FRIENDLY_FIELD_NAME[key] ? intl.formatMessage({ id: FRIENDLY_FIELD_NAME[key] }) : ''
    const errMessage = FRIENDLY_ERROR_MESSAGE[errorContent]
      ? intl.formatMessage({ id: FRIENDLY_ERROR_MESSAGE[errorContent] })
      : errorContent
    const message = FRIENDLY_FIELD_NAME[key] ? `${fieldName} ${errMessage}` : next
    return `${acc ? `${acc}\n` : ''}${message}`
  }, '')
  return str.trim()
}

export const getColumnValue = (keyValues, orderRequest, intl) => {
  const rowData = keyValues.map(key => {
    const value = orderRequest?.key || ''
    switch (key) {
      case 'response_body':
        return errorMessage(value.error?.validation_errors || value.error, intl)
      case 'status':
        return intl.formatMessage({ id: ORDER_REQUEST_STATUS_LABEL[value] })
      case 'request_body.delivery.delivery_timeslot':
        return value ? `${value.start_time}-${value.end_time}` : ''
      default:
        return value
    }
  })
  return rowData
}

export const mappingOrderPrinters = orders => {
  return orders.map(order => {
    const parcel = {
      id: order.id,
      requestedTrackingId: order.response_body?.data?.tracking_id,
      cod: order.request_body?.delivery?.cash_on_delivery,
      parcelDescription: order.request_body?.items
        ? friendlyItemsDescription(order.request_body?.items)
        : order.request_body?.parcel_details?.customs_description
    }
    const receiver = {
      toName: order.request_body?.to?.name,
      toContact: order.request_body?.to?.contact_number,
      toAddress: {
        toAddress1: order.request_body?.to?.address_line1,
        toAddress2: order.request_body?.to?.address_line2,
        toCountry: COUNTRY_LABEL[order.request_body?.to?.country_code?.toUpperCase()],
        toPostCode: order.request_body?.to?.post_code
      }
    }
    const shipper = {
      fromName: order.request_body?.from?.name
    }
    return {
      parcel,
      receiver,
      shipper
    }
  })
}

export const fetchFile = url => {
  return fetch(url).then(response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}`)
    }
    return response.blob()
  })
}

export const retrieveOrderRequest = (requests, intl) => {
  const contentData = requests.map(request => {
    const { response_body, request_body, parcel, status } = request
    const { to, delivery, parcel_details } = request_body
    const { customs_description } = parcel_details
    const { name, address_line1 } = to
    const trackingId = parcel ? response_body?.data.tracking_id : ''
    const parcelType = parcel?.type ? TRANSLATION_KEY_BY_TYPE[parcel.type] : ''
    let remark = ''
    if (!parcel) {
      const err = response_body?.error?.validation_errors || response_body?.error
      remark = errorMessage(err, intl)
      if (remark.includes('\n')) {
        remark = uniq(remark.split('\n')).join('\n')
      }
    }
    return [trackingId, parcelType, name, address_line1, delivery?.cash_on_delivery || '', customs_description, remark, ORDER_REQUEST_STATUS_LABEL[status]]
  })
  return {
    fields: Object.values(ORDER_REQUEST_HEADER_FIELDS).map(key => intl.formatMessage({ id: key })),
    data: contentData
  }
}
