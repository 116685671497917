import React from 'react'
import { OCSubStep } from 'components/OCSubStep'
import { T } from '@nv/react-commons/src/Components'
import { useIntl } from 'hooks/common'
import { Hspace } from 'components/Hspace'
import { faEdit } from '@fa-pro-light/faEdit'

import { Description, FlexBlock, InlineBlock, SeperatedLine, StyledCard } from 'containers/FPLOrderCreate/styles'
import { INPUT_METHODS } from 'containers/FPLOrderCreate/constants'
import { StyledIconButton } from './styles'
import { PREVIEW_SOURCE } from './constants';

interface PreviewStepInfoProps {
  quantity: number
  totalValue: number
  unit: string
  totalRecipient?: number
  translatedKeys: {
    titleKey: string
    totalPluralKey: string
    totalSingularKey: string
    totalValueKey: string
    creationMethodKey: string
    pluralRecipientKey?: string
    singularRecipientKey?: string
  }
  creationMethod?: string
  icon: JSX.Element
  source?: PREVIEW_SOURCE
  onEditStep: () => void
}

export const PreviewStepInfo = ({
  quantity,
  totalValue,
  unit,
  translatedKeys,
  creationMethod,
  icon,
  totalRecipient,
  source,
  onEditStep
}: PreviewStepInfoProps) => {
  const intl = useIntl()
  const { titleKey, totalPluralKey,
    totalSingularKey, totalValueKey,
    creationMethodKey, pluralRecipientKey,
    singularRecipientKey } = translatedKeys

  const renderPreviewDocument = () => {
    return <>
      <InlineBlock>
        {quantity}{' '}
        {intl.formatMessage({ id: `${quantity > 1 ? totalPluralKey : totalSingularKey}` }).toLowerCase()}
      </InlineBlock>
      <Hspace width={16} />
      <SeperatedLine />
      <Hspace width={16} />
      <InlineBlock>
        <Description>
          <T id={totalValueKey} />
        </Description>
        <Hspace width={4} />
        <InlineBlock>
          {totalValue?.toFixed(2)}
          <Hspace width={3} />
          {unit?.toUpperCase()}
        </InlineBlock>
      </InlineBlock>
    </>
  }

  const renderPreviewInfoByUploadMethod = () => {
    return <>
      <InlineBlock>
        {quantity}{' '}
        {intl.formatMessage({ id: `${quantity > 1 ? totalPluralKey : totalSingularKey}` }).toLowerCase()}
      </InlineBlock>
      <Hspace width={16} />
      <SeperatedLine />
      <Hspace width={16} />
      <InlineBlock>
        <Description>
          <T id={totalValueKey} />
        </Description>
        <Hspace width={4} />
        <InlineBlock>
          {totalValue?.toFixed(2)}
          <Hspace width={3} />
          {unit?.toUpperCase()}
        </InlineBlock>
      </InlineBlock>
      <Hspace width={16} />
      <SeperatedLine />
      <Hspace width={16} />
      <InlineBlock>
        <Description>
          <T id={creationMethodKey} />
        </Description>
        <Hspace width={3} />
        <Description>
          <T
            id='upload_file'
          />
        </Description>
      </InlineBlock>
    </>
  }

  const renderPreviewInfoByInputMethod = () => {
    return <>
      <InlineBlock>
        {totalRecipient}{' '}
        {intl.formatMessage({ id: `${totalRecipient > 1 ? pluralRecipientKey : singularRecipientKey}` }).toLowerCase()}
      </InlineBlock>
      <Hspace width={16} />
      <SeperatedLine />
      <Hspace width={16} />
      <InlineBlock>
        {quantity}{' '}
        {intl.formatMessage({ id: `${quantity > 1 ? totalPluralKey : totalSingularKey}` }).toLowerCase()}
      </InlineBlock>
      <Hspace width={16} />
      <SeperatedLine />
      <Hspace width={16} />
      <InlineBlock>
        <Description>
          <T id={totalValueKey} />
        </Description>
        <Hspace width={4} />
        <InlineBlock>
          {totalValue?.toFixed(2)}
          <Hspace width={3} />
          {unit?.toUpperCase()}
        </InlineBlock>
      </InlineBlock>
      <Hspace width={16} />
      <SeperatedLine />
      <Hspace width={16} />
      <InlineBlock>
        <Description>
          <T id={creationMethodKey} />
        </Description>
        <Hspace width={3} />
        <Description>
          <T
            id='international_creation_method_by_keyboard'
          />
        </Description>
      </InlineBlock>
    </>
  }

  const renderPreviewInfo = () => {
    if (source === PREVIEW_SOURCE.DOCUMENT) {
      return renderPreviewDocument()
    }
    if (creationMethod === INPUT_METHODS.FILE) {
      return renderPreviewInfoByUploadMethod()
    }
    return renderPreviewInfoByInputMethod()
  }

  return (
    <>
      <StyledCard center='center'>
        <FlexBlock>
          <OCSubStep
            status={OCSubStep.DISABLE}
            title={intl.formatMessage({ id: titleKey })}
            icon={icon}
            renderPreview={() => {
              return (
                <InlineBlock>
                  {renderPreviewInfo()}
                </InlineBlock>
              )
            }}
          />
          <StyledIconButton icon={faEdit} onClick={onEditStep} data-analyticsid='fpleditOCSummary' />
        </FlexBlock>
      </StyledCard>
    </>
  )
}
