import React, { useMemo } from 'react'
import { StyledForm } from 'containers/FPLOrderCreate/styles'
import { T, Col, Row } from '@nv/react-commons/src/Components'
import { SmallInput } from './styles'
import { StyledFormItem } from 'containers/FPLOrderRequestList/styles'
import { RedAsterisk } from 'containers/FPLRegistration/styles'
import { useIntl } from 'hooks/common'
import { getCreationBoxFormRules } from './utils'

export const FormBox = ({ form }) => {
  const intl = useIntl()
  const rulesByField = useMemo(() => getCreationBoxFormRules(intl), [intl])
  return (
      <StyledForm>
        <Row>
          <Col span={6}>
            <StyledFormItem
              label={
                <>
                  <T id='shipper_box_id' />
                  <RedAsterisk />
                </>
              }
            >
              {form.getFieldDecorator('boxId', {
                initialValue: '',
                rules: rulesByField.boxId
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
          <Col span={5}>
            <StyledFormItem
              label={
                <>
                  <T id='goods_description' />
                  <RedAsterisk />
                </>
              }
            >
              {form.getFieldDecorator('goodsDescription', {
                initialValue: '',
                rules: rulesByField.goodsDescription
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
          <Col span={3}>
            <StyledFormItem
              label={
                <>
                  <T id='no_of_parcels' />
                  <RedAsterisk />
                </>
              }
            >
              {form.getFieldDecorator('quantity', {
                initialValue: '',
                rules: rulesByField.quantity
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
          <Col span={3}>
            <StyledFormItem
              label={
                <>
                  <T id='goods_value' />
                  <RedAsterisk />
                </>
              }
            >
              {form.getFieldDecorator('goodsValue', {
                initialValue: '',
                rules: rulesByField.goodsValue
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
          <Col span={4}>
            <div className='flex'>
              <StyledFormItem label='L'>
                {form.getFieldDecorator('length', {
                  initialValue: '',
                  rules: rulesByField.length
                })(<SmallInput />)}
              </StyledFormItem>

              <StyledFormItem label='W'>
                {form.getFieldDecorator('width', {
                  initialValue: '',
                  rules: rulesByField.width
                })(<SmallInput />)}
              </StyledFormItem>
              <StyledFormItem label='H'>
                {form.getFieldDecorator('height', {
                  initialValue: '',
                  rules: rulesByField.height
                })(<SmallInput />)}
              </StyledFormItem>
            </div>
          </Col>
          <Col span={3}>
            <StyledFormItem
              label={
                <>
                  <T id='weight_kg' />
                </>
              }
            >
              {form.getFieldDecorator('weight', {
                initialValue: '',
                rules: rulesByField.weight
              })(<SmallInput />)}
            </StyledFormItem>
          </Col>
        </Row>
      </StyledForm>
  )
}

