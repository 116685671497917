import React, { useContext, useMemo, useState } from 'react'
import { StyledFlexBlock } from 'containers/FPLOrderCreate/styles'
import { T, Form } from '@nv/react-commons/src/Components'
import { CancelButton, HorizontalSeperateLine, MissingMessage, NoMarginIcon } from './styles';
import { buildBoxesForB2BOrders } from './utils'
import { compose } from 'redux'
import { BoxTable } from './BoxTable'
import { Vspace } from 'components/Vspace'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { max } from 'lodash'
import Button from 'components/Button'
import { EditBoxModal } from './EditBoxModal'
import { FormBox } from './FormBox'
import { Text } from 'components/Text';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

const initialState = {
  editBoxNo: null,
  visibleModal: false,
  isResetEditForm: false
}

const BoxArea = ({ form, isShowMissingBox, onShowMissingBox }) => {
  const [state, setState] = useState(initialState)
  const { editBoxNo, visibleModal, isResetEditForm } = state

  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    activeGroupId,
    orderFormGroup,
    selectedService,
    visibleAddBoxForm
  } = ocState

  const destinationCountry = useMemo(() => selectedService.destination_country, [selectedService])

  const draftBoxes = useMemo(() => {
    return orderFormGroup[activeGroupId].draftBoxes
  }, [orderFormGroup, activeGroupId])

  const validateFields = () => {
    form.validateFields((err, values) => {
      if (!err) {
        const boxNo = !draftBoxes.length ? 1 : max(draftBoxes.map(order => order.boxNo)) + 1
        const newBoxOrdersOfForm = [...draftBoxes, { ...values, boxNo }]
        const boxData = buildBoxesForB2BOrders(
          newBoxOrdersOfForm,
          orderFormGroup[activeGroupId].address,
          destinationCountry
        )
        const newOrderGroupBox = { ...orderFormGroup }
        newOrderGroupBox[activeGroupId].draftBoxes = newBoxOrdersOfForm
        newOrderGroupBox[activeGroupId].confirmedBoxes = boxData
        updateOCState({ orderFormGroup: newOrderGroupBox, visibleAddBoxForm: true })
        form.resetFields()
        onShowMissingBox(false)
      }
    })
  }

  const addNewBag = () => {
    if (!visibleAddBoxForm) {
      updateOCState({ visibleAddBoxForm: true })
      setState(prevState => ({ ...prevState, isAdding: true }))
    } else {
      validateFields()
    }
  }

  const deleteOrderInGroup = (order) => {
    const updatedOrders = draftBoxes.filter((or) => or.boxNo !== order.boxNo);

    updateOCState({ visibleAddBoxForm: updatedOrders.length === 0 ? true : visibleAddBoxForm })

    const boxData = buildBoxesForB2BOrders(
      updatedOrders,
      orderFormGroup[activeGroupId].address,
      destinationCountry
    );

    const newOrderGroupBox = {
      ...orderFormGroup,
      [activeGroupId]: {
        ...orderFormGroup[activeGroupId],
        draftBoxes: updatedOrders,
        confirmedBoxes: boxData,
      },
    };

    updateOCState({ orderFormGroup: newOrderGroupBox });
  };


  const editOrder = order => {
    setState(prevState => ({
      ...prevState,
      editBoxNo: order,
      visibleModal: true,
      isResetEditForm: false
    }))
    updateOCState({ visibleAddBoxForm: false })
  }

  const cancelAddNewBage = () => {
    setState(prevState => ({ ...prevState, isResetEditForm: true }))
    updateOCState({ visibleAddBoxForm: false })
  }

  const closeModal = () => {
    setState(prevState => ({ ...prevState, visibleModal: false, isResetEditForm: true }))
    form.resetFields()
  }

  const saveEditOrder = order => {
    const updatedOrders = draftBoxes.map(or => {
      if (or.boxNo === order.boxNo) {
        return order
      }
      return or
    })
    setState(prevState => ({
      ...prevState,
      editBoxNo: null,
      visibleModal: false
    }))
    const boxData = buildBoxesForB2BOrders(
      updatedOrders,
      orderFormGroup[activeGroupId].address,
      destinationCountry
    )

    const newOrderGroupBox = { ...orderFormGroup }
    newOrderGroupBox[activeGroupId].draftBoxes = updatedOrders
    newOrderGroupBox[activeGroupId].confirmedBoxes = boxData
    updateOCState({ orderFormGroup: newOrderGroupBox })
  }

  return (
    <>
      <BoxTable orders={draftBoxes} onDeleteOrder={deleteOrderInGroup} onEditOrder={editOrder} />

      <Vspace height={4} />
      {draftBoxes.length > 0 && <HorizontalSeperateLine />}
      <Vspace height={14} />
      {visibleAddBoxForm && !visibleModal && <FormBox form={form} />}
      {isShowMissingBox && <>
        <MissingMessage>
          <NoMarginIcon icon={faExclamationCircle} color='#F06C00' />
          <Text id='missing_enter_value' />
        </MissingMessage>
        <Vspace height={14} />
      </>}
      <StyledFlexBlock>
        <Button onClick={addNewBag} type='default' size='small' >
          <T id={visibleAddBoxForm ? 'save_and_add_new_box' : 'add_new_box'} allCaps />
        </Button>
        {visibleAddBoxForm && draftBoxes.length > 0 && (
          <CancelButton onClick={cancelAddNewBage} size='small'>
            <T id='cancel' allCaps />
          </CancelButton>
        )}
      </StyledFlexBlock>
      {editBoxNo && (
        <EditBoxModal
          visible={visibleModal}
          order={editBoxNo}
          isResetEditForm={isResetEditForm}
          onCancel={closeModal}
          onOk={saveEditOrder}
        />
      )}
    </>
  )
}

export const BoxSection = compose(Form.create())(React.memo(BoxArea))
